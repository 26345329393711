import { FunctionComponent } from 'react'
import iconInstagram from '~public/images/icon-instagram.svg'
import iconTwitter from '~public/images/icon-twitter.svg'

export const SocialIcons: FunctionComponent = () => (
  <div className="flex flex-wrap gap-s_2">
    <a href="https://instagram.com/swag_left">
      <img src={iconInstagram.src} alt="Instagram" />
    </a>
    <a href="https://twitter.com/swag_left">
      <img src={iconTwitter.src} alt="Twitter" />
    </a>
  </div>
)
