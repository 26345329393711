import { FunctionComponent } from 'react'
import Link from 'next/link'
import { Image as ImageType } from '../src/types'
import styles from './WraparoundProduct.module.css'

type Props = {
  image: ImageType
}

const WraparoundProduct: FunctionComponent<Props> = ({ image }) => (
  <div className={`content-breakout ${styles.wraparound}`}>
    <div
      className={styles.pictures}
      style={{ backgroundImage: `url(${image.url})` }}
    />
    <Link href="/shop" passHref className="button">
      SHOP THE TEE
    </Link>
  </div>
)

export default WraparoundProduct
