import { FunctionComponent } from 'react'
import Image from 'next/image'
import { Quote } from '../src/types'
import styles from './Quote.module.css'

type Props = { quote: Quote }

const Quote: FunctionComponent<Props> = ({
  quote: { authorDescription, authorName, avatar, text },
}) => (
  <figure className={styles.quote}>
    <blockquote>{text.trim()}</blockquote>
    <figcaption>
      <div className="flex flex-nowrap justify-center items-center gap-s0">
        <div className={`relative flex-shrink-0 ${styles.avatar}`}>
          <Image src={avatar.url} alt={avatar.alt} fill />
        </div>
        <div className="stack-l gap-s_2 items-start">
          <div className={styles.name}>{authorName}</div>
          <div className={styles.description}>{authorDescription}</div>
        </div>
      </div>
    </figcaption>
  </figure>
)

export default Quote
