import { __, times } from 'ramda'
import { FunctionComponent } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { Image as ImageType } from '../src/types'
import styles from './TripleProduct.module.css'

type Props = {
  image: ImageType
}

const TripleProduct: FunctionComponent<Props> = ({ image }) => {
  return (
    <div className={`stack-l gap-s0 ${styles.tripleProduct}`}>
      <div className={styles.images}>
        {times(
          (index) => (
            <div key={index}>
              <Image src={image.url} alt={image.alt} width={540} height={533} />
            </div>
          ),
          3
        )}
      </div>

      <Link href="/shop" passHref className="button">
        SHOP THE TEE
      </Link>
    </div>
  )
}

export default TripleProduct
