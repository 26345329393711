import { FunctionComponent } from 'react'
import Image, { ImageProps } from 'next/image'
import iconLink from '~public/images/icon-link.svg'

export type Props = {
  title: string
  subtitle: string
  bodyHtml: string
  linkUrl: string
  linkText: string
  imageSrc: Exclude<ImageProps['src'], string>
  imageAlt: string
  imageCreditUrl?: string
  imageCreditText?: string
}

const CampaignCard: FunctionComponent<Props> = ({
  bodyHtml,
  imageAlt,
  imageSrc,
  imageCreditUrl,
  imageCreditText,
  linkText,
  linkUrl,
  subtitle,
  title,
}) => (
  <div className="stack-l gap-s0 p-s3 items-start">
    <figure className="relative w-full space-y-s_4">
      <Image
        src={imageSrc}
        alt={imageAlt}
        sizes="(max-width: 40rem) 90vw, (max-width: 90rem) 45vw, 40rem"
      />
      <figcaption className="text-f_2 text-right text-fineprint">
        {imageCreditUrl && imageCreditText ? (
          <>
            Photo courtesy of{' '}
            <a className="link" href={imageCreditUrl}>
              {imageCreditText}
            </a>
          </>
        ) : (
          <>&nbsp;</>
        )}
      </figcaption>
    </figure>
    <div className="stack-l gap-s0 flex-grow">
      <h2>
        <div role="doc-subtitle">{subtitle}</div>
        {title}
      </h2>

      <p
        className="with-links"
        dangerouslySetInnerHTML={{ __html: bodyHtml }}
      />
    </div>

    <a
      href={linkUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="text-f1 link link-with-icon"
    >
      <img src={iconLink.src} alt="" /> {linkText}
    </a>
  </div>
)

export default CampaignCard
