import { FunctionComponent, ReactElement } from 'react'

export type Props = {
  title: string
  subtitle?: string
  bodyHtml: string
  linkUrl?: string
  linkText?: string
  linkIcon: StaticImageData
  icons?: ReactElement
}

const CompanyCard: FunctionComponent<Props> = ({
  bodyHtml,
  linkText,
  linkUrl,
  linkIcon,
  subtitle,
  title,
  icons,
}) => (
  <div className="stack-l gap-s2 p-s3 items-start">
    <div className="stack-l gap-s0 flex-grow">
      <h2>
        {subtitle && <div role="doc-subtitle">{subtitle}</div>}
        {title}
      </h2>

      <p
        className="with-links"
        dangerouslySetInnerHTML={{ __html: bodyHtml }}
      />
    </div>

    {linkUrl && (
      <a
        href={linkUrl}
        target="_blank"
        rel="noreferrer"
        className="link link-with-icon text-f1"
      >
        <img src={linkIcon.src} alt="" /> {linkText}
      </a>
    )}

    {icons}
  </div>
)

export default CompanyCard
