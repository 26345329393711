import { FunctionComponent, useContext } from 'react'
import { CampaignContext } from '../src/state/CampaignContext'
import CampaignCard, { Props as CampaignCardProps } from './CampaignCard'

const CampaignCards: FunctionComponent = () => {
  const { artist, cause } = useContext(CampaignContext)

  const artistProps: CampaignCardProps = {
    title: artist.name,
    subtitle: 'The Artist',
    bodyHtml: artist.descriptionHtml,
    ...artist,
    imageSrc: artist.imageSrc,
    imageCreditUrl: artist.imageCreditUrl,
    imageCreditText: artist.imageCreditText,
  }

  const causeProps: CampaignCardProps = {
    title: cause.name,
    subtitle: 'The Cause',
    bodyHtml: cause.descriptionHtml,
    ...cause,
    imageSrc: cause.imageSrc,
    imageCreditUrl: cause.imageCreditUrl,
    imageCreditText: cause.imageCreditText,
  }

  return (
    <div className="switcher-l flex-borders">
      <CampaignCard {...artistProps} />
      <CampaignCard {...causeProps} />
    </div>
  )
}

export default CampaignCards
