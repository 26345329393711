import { FunctionComponent } from 'react'
import { CampaignContext } from '../../src/state/CampaignContext'
import { Header } from '../Header'
import { Layout } from '../Layout'
import { CampaignHero } from '../CampaignHero'
import Blurb from '../Blurb'
import CampaignCards from '../CampaignCards'
import WraparoundProduct from '../WraparoundProduct'
import Instagrid from '../Instagrid'
import Quote from '../Quote'
import CompanyCards from '../CompanyCards'
import TripleProduct from '../TripleProduct'
import { Campaign } from '../../src/types'

export type Props = {
  campaign: Campaign
}

export const HomePage: FunctionComponent<Props> = ({ campaign }) => {
  const { instagridImages, quote, wraparoundImage, tripleImage } = campaign

  return (
    <CampaignContext.Provider value={campaign}>
      <Layout header={<Header overlay />}>
        <div className="content-breakout" id="product">
          <CampaignHero />
        </div>
        <div className="stack-l flex-borders border-bottom">
          <Blurb>
            <p>
              SwagLeft works with artists, progressive causes & you to raise
              money and do some good.
            </p>
          </Blurb>
          <div id="campaign">
            <CampaignCards />
          </div>
        </div>
        <div className="content-breakout">
          <WraparoundProduct image={wraparoundImage} />
        </div>
        <Instagrid images={instagridImages.slice(0, 8)} />
        <Quote quote={quote} />
        <div id="about" className="border-top border-bottom">
          <CompanyCards />
        </div>
        <TripleProduct image={tripleImage} />
      </Layout>
    </CampaignContext.Provider>
  )
}
