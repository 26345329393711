import { FunctionComponent } from 'react'
import Image from 'next/image'
import { Image as ImageType } from '../src/types'
import styles from './Instagrid.module.css'

type Props = {
  images: ImageType[]
}

const Instagrid: FunctionComponent<Props> = ({ images }) => (
  <>
    <ul className={`color-inverse items-center ${styles.marquee}`}>
      <li>#swagleft</li>
      <li>@swag_left</li>
      <li>#swagleft</li>
      <li>@swag_left</li>
      <li>#swagleft</li>
      <li>@swag_left</li>
      <li>#swagleft</li>
    </ul>

    <div className={styles.instagrid}>
      {images.map(({ url, alt }, index) => (
        <div key={index}>
          <Image src={url} alt={alt} fill />
        </div>
      ))}
    </div>
  </>
)

export default Instagrid
