import { FunctionComponent } from 'react'
import CompanyCard, { Props as CompanyCardProps } from './CompanyCard'
import { SocialIcons } from './SocialIcons'
import iconEmail from '~public/images/icon-email.svg'
import iconLink from '~public/images/icon-link.svg'
import iconUsaMade from '~public/images/icon-usa-made.svg'
import iconSweatshopFree from '~public/images/icon-sweatshop-free.svg'

const CompanyCards: FunctionComponent = () => {
  const aboutIcons = <SocialIcons />

  const aboutProps: CompanyCardProps = {
    title: 'About SwagLeft',
    bodyHtml: `We’re a small company dedicated to connecting cool artists
      with progressive shoppers so they can team up and
      fundraise for causes they care about.`,
    linkUrl: 'mailto:support@swagleft.com',
    linkText: 'Support@SwagLeft.com',
    linkIcon: iconEmail,
    icons: aboutIcons,
  }

  const guaranteeIcons = (
    <div className="flex flex-wrap gap-s_2">
      <img src={iconUsaMade.src} alt="USA Made" />
      <img src={iconSweatshopFree.src} alt="Sweatshop Free" />
    </div>
  )

  const guaranteeProps: CompanyCardProps = {
    title: 'Our Guarantee',
    bodyHtml: `
      We source products with an ethical supply chain and
      all our tees are screen printed in Seattle, WA,
      by a super cool, non-profit youth arts center.
    `,
    linkUrl: '/faq',
    linkText: 'Return & Exchange Policy',
    linkIcon: iconLink,
    icons: guaranteeIcons,
  }

  return (
    <div className="switcher-l flex-borders">
      <CompanyCard {...aboutProps} />
      <CompanyCard {...guaranteeProps} />
    </div>
  )
}

export default CompanyCards
