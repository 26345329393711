import React, { FunctionComponent, useContext } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { CampaignContext } from '../src/state/CampaignContext'
import styles from './CampaignHero.module.css'

type ForegroundStyle = {
  text: string
  button: string
}

const foregroundStyleMap: { [tone: string]: ForegroundStyle } = {
  light: { text: styles.foregroundLight, button: '' },
  dark: { text: styles.foregroundDark, button: 'button-transparent' },
}

export const CampaignHero: FunctionComponent = () => {
  const campaign = useContext(CampaignContext)
  const {
    heroText,
    callToAction,
    heroImage,
    heroForegroundTone,
    heroCaptionHtml,
  } = campaign
  const foregroundStyle = foregroundStyleMap[heroForegroundTone]

  return (
    <div className={`${styles.campaignHero} ${foregroundStyle.text}`}>
      <div className={`stack-l gap-s0 ${styles.heroText} relative`}>
        <Image
          src={heroImage.url}
          alt={heroImage.alt}
          className={styles.heroImage}
          priority={true}
          fill
        />

        <div className="z-1 flex-grow flex flex-col gap-s0 justify-center">
          <h1 className="z-1 text-f5 sm:text-f6 lg:text-f7 whitespace-pre-line">
            {heroText}
          </h1>

          <div className="z-1">
            <Link
              href="/shop"
              passHref
              className={`button ${foregroundStyle.button}`}
            >
              SHOP THE TEE
            </Link>
          </div>
        </div>

        {heroCaptionHtml && (
          <div
            className="z-1 text-f_1 md:text-f0 with-links"
            dangerouslySetInnerHTML={{ __html: heroCaptionHtml }}
          />
        )}
      </div>
      <p className={`${styles.fundraising} z-1`}>{callToAction}</p>
    </div>
  )
}
